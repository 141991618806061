.HeroSection {
  background-color: #ffffff;
  padding: 50px 0px;
}

.MainHeading {
  background: #f8f9fa;
  border-radius: 40px;
  height: auto;
  min-width: fit-content;
  padding: 10px 20px;
}

.MainHeading p {
  font-family: 'Gilroy-SemiBold';
  color: #131416;
  font-size: 15.4px;
  margin: 0;
}

.carrot {
  font-family: 'Gilroy-Bold';
}

.MainSecondHeading h1 {
  font-family: "Gilroy-Bold";
  font-size: calc(0.4 * 103px);
  line-height: calc(0.45 * 103px);
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
  color: #4a4a4a;
}

.MainSecondHeading span {
  color: #fe5000;
}

.subHeading p {
  font-family: "Gilroy-Medium";
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #5e5e5e;
}

.LastHeading {
  font-family: "Gilroy-Bold";
  font-size: 28px;
  line-height: 41px;
  font-weight: bold;
  color: #4a4a4a;
}

.lastSubHeading {
  font-family: "Gilroy-Medium";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #5e5e5e;
}

.lastbox {
  background: #ffffff;
  box-shadow: 0px 4px 150px 20px rgba(254, 80, 0, 0.05);
  border-radius: 15px;
}

.add-gutter {
  padding: 0px 15px;
}

.business-section-1 {
  position: relative;
  min-width: fit-content;
  width: 70%;
  max-width: 90%;
  border: 1px solid #cecece;
  border-radius: 100px;
  margin: auto;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 3px 3px 30px;
}

.business-section-1 input {
  max-height: 30px;
  width: 60%;
  background: transparent;
  border: none;
  border-radius: 100px;
  color: #767675;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 50px;
  font-family: "Gilroy-Medium";
  font-size: 16px !important;
  line-height: 20px;
}

@media only screen and (min-width: 420px) {
  .MainSecondHeading h1 {
    font-size: calc(0.5 * 103px);
    line-height: calc(0.55 * 103px);
  }
}

@media only screen and (min-width: 734px) {
  .business-section-1 {
    width: 55%;
    margin-left: 0;
  }

  .business-section-1 input {
    font-size: 18px !important;
    line-height: 25px;
  }

  .HeroSection {
    background-color: #ffffff;
    padding: 60px 0px;
  }

  .MainSecondHeading h1 {
    font-size: calc(0.6 * 103px);
    line-height: calc(0.7 * 103px);
  }
}

@media only screen and (min-width: 1069px) {
  .MainSecondHeading h1 {
    font-size: calc(0.7 * 103px);
    line-height: calc(0.75 * 103px);
  }
}