@media screen and (max-width: 768px) {
  body {
    background-image: url(../images/mobile-background.png);
    background-repeat: repeat;
    background-position: center center;
  }

  #header #mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 18px 0;
  }

  #logo {
    height: 17px;
    margin-left: 30px;
    margin-bottom: 0px;
    display: none;
  }

  .show-sm {
    display: none;
  }

  .icon.small {
    display: none;
  }

  .icon.medium {
    margin-left: 29px;
    margin-right: 0px;
  }

  .typography {
    text-align: left;
    white-space: pre;
  }

  #menu-button {
    display: block;
  }

  #menu {
    width: 50%;
    min-width: 160px;
    position: absolute;
    z-index: 10000000000;
    top: 85px;
    right: 60px;
    height: auto;
    min-height: 107px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    display: none;
    padding-left: 20px;
    background-color: #eef5f5;
    border-radius: 5px;
  }

  #menu > img {
    margin-bottom: 70px;
  }

  #menu > * {
    padding: 0;
    font-size: 15px;
    margin-top: 20px;
  }

  .active-menu {
    border: 0;
    padding: 0 !important;
  }

  #content {
    width: 100%;
    margin-top: 0;
    padding-right: 25px;
    padding-top: 30px;
  }

  #info {
    height: auto;
    flex-direction: column;
    margin-bottom: 20px;
  }

  #info > .detail div:nth-child(1) {
    font-size: 14px;
    margin-bottom: 15px;
    text-decoration: underline;
  }

  #info > .detail:nth-child(1) div:nth-child(2)::after {
    content: "";
  }

  #info > .detail div:nth-child(2)::after {
    content: ":";
  }

  #info > .detail:nth-child(1) {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }

  #info > .detail:nth-child(1) div:nth-child(1) {
    font-size: 56px;
    font-weight: bolder;
    margin-bottom: 22px;
    text-decoration: none;
  }

  .detail > div:nth-child(1) {
    width: auto;
    white-space: unset;
    overflow: unset;
    text-align: unset;
  }

  #info > .detail:nth-child(1) div:nth-child(2) {
    font-weight: bold;
  }

  .detail {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding-left: 30px;
    padding-right: 30px;
    overflow: auto;
    justify-content: space-between;
    align-items: flex-start !important;
    border-left: 0;
    padding: 0;
  }

  #dashboard-header-parent {
    display: none;
  }

  #dashboard-name-header {
    max-width: 375px;
  }

  #dashboard {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 20px 25px 75px 25px;
    align-items: center;
  }

  #dashboard-cards-upper {
    flex-direction: column;
  }

  /* #dashboard-card-upper-balance {
    padding: 0 8px 31px 18px;
  } */

  /* #dashboard-card-upper-details {
    width: 302px;
    height: 146px;
    align-self: center;
    margin-top: -47px;
  } */

  #actions {
    flex-wrap: wrap;
  }

  #actions > .typography {
    margin-top: 10px;
  }

  #datatable {
    width: 250%;
    margin-top: 19px;
  }

  #authentication {
    flex-direction: column;
  }

  #authentication #left {
    width: 100%;
    max-width: 100%;
    height: 63vh;
    min-height: 63vh;
    background-size: auto;
    padding: 0;
  }

  .illustration-2 {
    background-image: url('../images/get-started-mobile-illustration.svg');
    background-color: #ffffff !important;
  }

  .xmark {
    width: 10.79px;
    height: 10.79px;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  #authentication #right {
    width: 100%;
    min-width: 0;
    padding: 0 31px 0 31px;
  }

  #container {
    position: relative;
  }

  .mobile-display-block {
    display: block;
  }

  .mobile-display-none {
    display: none;
  }

  .align-center {
    text-align: center;
    align-self: center;
  }

  .align-left {
    text-align: left;
    align-self: flex-start;
  }

  .align-right {
    text-align: right;
    align-self: flex-end;
  }

  .heading {
    font-size: 1.17rem;
    line-height: 1.3rem;
    align-self: left;
    max-width: 282px;
  }

  .subheading {
    max-width: 327px;
    font-size: 15px;
    line-height: 19px;
  }

  #overlay {
    width: calc(100% - 40px);
    padding: 20px;
  }

  #overlay .typography {
    white-space: normal;
    padding: 10px;
  }

  .center {
    display: block;
  }

  .center + .input {
    margin-top: 29px;
  }

  .slider-container {
    width: 90%;
    margin-left: 5%;
  }

  .spread-button {
    display: flex;
  }

  #footer {
    width: calc(100% - 60px);
    text-align: center;
    position: static;
    margin-left: 30px;
    white-space: break-spaces;
  }

  #keypad {
    max-width: 327px;
    margin: auto;
  }

  #keypad-header {
    margin: 18px 0 10px 0;
  }

  #keypad #header {
    justify-content: flex-start;
    margin-top: 15px;
  }

  .teller-image {
    align-self: center;
  }

  .teller-image img {
    width: 67.9px;
  }

  .heading.keypad {
    align-self: center;
    font-size: 16px;
    line-height: 24px;
    margin: 16.78px 0 16px 0;
    font-family: 'Gilroy-SemiBold'
  }

  #keypad-amount {
    font-size: 12px;
    line-height: 18px;
    align-self: flex-end;
    margin-bottom: 31px;
  }

  #no_placeholder_input {
    margin-left: auto;
    margin-top: 0;
  }

  #keypad-value {
    font-size: 57.6px;
    line-height: 67.5px;
    align-self: center;
    margin: 6px 0 7px 0;
    height: 70px;
  }

  .keypad-column {
    width: 278px;
    height: 70px;
  }

  .keypad-row {
    font-size: 30px;
    line-height: 18px;
    letter-spacing: -0.01px;
    height: 70px;
    width: 70px;
  }

  #keypad-full-button {
    margin-top: 0;
  }

  #keypad-dashboard a {
    color: #fff;
  }

  .keypad-label {
    font-size: 14px;
    line-height: 18.2px;
    align-self: center;
  }

  #keypad-max {
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #f9fafd;
    margin-top: 24px;
    cursor: pointer;
  }

  #rates {
    height: 30px;
    font-size: 10.23px;
    line-height: 13px;
  }

  #logout {
    position: relative;
    top: 0;
    margin-bottom: 20px;
    font-weight: 500;
  }

  #empty {
    width: 305.5px;
  }

  #application-successful {
    padding-top: calc((100vh - 550px) / 3);
  }

  #application-successful #successful-logo-container {
    min-height: 280px;
  }

  #application-successful #successful-logo {
    margin: 20px 0 36px 0;
    width: 280px;
  }

  #application-subtitle {
    text-align: center;
    margin: auto;
    margin-top: 6px;
    margin-bottom: 46px;
  }

  .button {
    font-size: 14.56px;
    line-height: 12.94px;
  }

  .country-code-icon {
    left: 78px;
  }

  #loading img {
    width: 45%;
  }

  /* Hamburger */
  #hamburger-parent {
    height: 100vh;
    width: 100vw;
    background-image: url(../images/mobile-background.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #fef0ed;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
  }

  #hamburger-name-header {
    height: 46.56px;
    padding: 0 23px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  #hamburger-name-header img {
    width: 19.8px;
    height: 19.8px;
  }

  #hamburger-logo-welcome-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 9.44px 0 17px 0;
  }

  #hamburger-carrot-logo-mark {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    background-color: #fe5000;
    text-align: center;
    margin: 0 0 7px 0;
  }

  #hamburger-carrot-logo-mark img {
    height: 19px;
    width: auto;
    margin: 9px 0 0 0;
  }

  #hamburger-welcome {
    font-family: 'Gilroy-Regular';
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #979797;
    margin: 0 0 1px 0;
    text-align: center;
  }

  #hamburger-user-name {
    font-family: 'Gilroy-Bold';
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #353535;
    text-align: center;
  }

  #hamburger-body {
    background-color: #ffffff;
    height: 100%;
    margin: 0 28px;
    border-radius: 10px 0;
    padding: 0 17px;
  }

  #hamburger-body .item {
    height: 70px;
    display: flex;
    align-items: center;
    font-family: 'Gilroy-Medium';
  }

  #hamburger-body .item:hover {
    color: #fe5000;
  }

  .horizontal-divider {
    height: 1px;
    width: 100%;
    background-color: #f0f0f0;
    padding: 0;
  }

  #dashboard-cards-lower {
    flex-direction: column;
  }

  .dashboard-action-card .text {
    font-family: 'Gilroy-Light';
  }

  /* #dashboard-cards-lower-actions {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  } */

  #dashboard-recent-right {
    width: 326px;
  }

  /* #dashboard-recent-heading {
    width: 305.5px;
  } */

  .dashboard-activity {
    min-width: fit-content;
    width: 97%;
    max-height: 430px;
    box-shadow: 0px 2px 10px rgba(191, 191, 191, 0.25);
    /* border: 1px solid #e2e7ec;
    box-shadow: none; */
    border-radius: 6px;
  }

  .dashboard-activity img.recent-icon {
    margin-right: 7px;
  }

  .dashboard-activity .item {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .dashboard-activity .item.level-1 {
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    min-height: 72px;
    max-height: 72px;
    padding: 0 10px 0 10px;
    column-gap: 7px;
  }

  .activity-status {
    margin: 0;
    padding: 1.5px 12px;
  }

  .level-3-container {
    max-width: none;
  }

  .dashboard-activity .level-3 {
    margin: 0 0 0 24px;
  }

  .col-margin {
    margin-bottom: 11px;
  }

  .dashboard-activity .item.level-4 {
    height: 51px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-top: 1px solid #e2e7ec;
    align-items: center;
  }

  .activity-default-text.refresh {
    margin-top: 0;
  }

  .activity-default-text.value {
    max-width: 122px;
  }

  .activity-default-text.value.top {
    max-width: 60px;
  }

  .activity-default-text.bold {
    font-family: 'Gilroy-Medium';
  }

  .vertical-line {
    height: 28.5px;
    width: 1px;
    background-color: #e2e7ec;
  }

  .activity-value-icon {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    align-items: center;
  }

  .activity-value-icon img {
    padding-bottom: 2px;
    cursor: pointer;
  }

  .dashboard-activity.mobile-display-block {
    display: block;
  }

  .dashboard-activity.mobile-display-none {
    display: none;
  }

  .heading.signin {
    margin: 30px 0 0 0;
  }

  .subheading.signin {
    margin: 7px 0 22px 0;
  }

  .signin-input {
    margin: 27px 0 0 0;
  }

  .signin-button {
    margin: 36px 0 0 0;
  }

  /* modal */
  .modal-content {
    width: 284px;
    height: 388px;
    margin: auto;
    border-radius: 29.1787px;
    text-align: center;
  }

  .modal-header {
    height: 34px;
  }

  .modal-body {
    padding: 0 17px;
  }

  .modal-body img {
    width: 122px;
    height: 99px;
  }

  .modal-body b {
    font-size: 15.13px;
    line-height: 22.7px;
  }

  .modal-body p {
    font-size: 13.77px;
    line-height: 19.42px;
  }

  button.btn-close {
    font-family: 'Gilroy-ExtraBold';
    color: #000000;
    font-size: 10px;
  }

  .info-subtitle {
    font-family: 'Gilroy-Light';
    font-size: 16px;
    line-height: 24px;
    max-width: 229px;
    margin: auto;
    margin-top: 56.24px;
  }

  .info-eligible {
    font-size: 50px;
    line-height: 67.5px;
    height: 71px;
    margin: 3px 0 0 0;
  }

  @media screen and (max-width: 300px) {
    .offer-amount {
      font-size: 2.5rem;
    }
  }

  .offer-amount-container {
    justify-content: center;
  }

  .heading.offer {
    font-family: 'Gilroy-SemiBold';
    margin: 10px auto 0 auto;
  }

  .offer-info {
    font-size: 12px;
    line-height: 16.34px;
    color: #7a7a7a;
    margin: 12px 0 42px 0;
  }

  .offer-center-image {
    position: relative;
    overflow: hidden;
  }

  .offer-center-image img {
    width: 100%;
    height: auto;
    max-width: 400px;
    min-width: 300px;
  }

  .offer-center-image-wrap:before {
    content: "";
    background: linear-gradient(
      180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 50.54%);
    position: absolute;
    width: 100%;
    min-width: 360px;
    height: 300px;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .offer-full-button {
    width: 100%;
    position: absolute;
    bottom: 50px;
  }

  .footer.offer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    margin-top: 0;
  }

  /* select vendor */
  .heading.vendor {
    margin: 15px 0 0 0;
  }

  .subheading.vendor {
    margin: 8px 0 0 0;
  }

  .vendor-cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 18px;
    row-gap: 18px;
    padding: 5px;
    margin: 24px 0 0 0;
  }

  .vendor-cards .item {
    width: 93px;
    height: 80.7px;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(191, 191, 191, 0.25);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vendor-cards .item .vendor-logo {
    width: 65px;
  }

  .vendor-button {
    margin: 100px 0 0 0;
  }

  /* repay method */
  .heading.repayMethod {
    margin: 31px 0 0 0;
  }

  .subheading.repayMethod {
    margin: 10px 0 0 0;
  }

  .repayMethod-options .item {
    margin: 30px 0 0 0;
  }

  .repayMethod-button {
    margin: 100px 0 0 0;
  }

  /* withdrawal and repayment Page */
  .disburseOptions-header {
    margin: 18px 0 18px 0;
  }

  .heading.disburseOptions {
    margin: 31px 0 0 0;
  }

  .subheading.disburseOptions {
    margin: 8px 0 17px 0;
  }

  .disburse-options {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 29px 0;
    column-gap: 6px;
    flex-wrap: wrap;
  }

  .disburse-options .item {
    width: calc(50% - 3px);
    min-width: 154px;
    padding: 0 0 0 8px;
    font-size: 14.56px;
    line-height: 18.93px;
    column-gap: 8px;
    flex-grow: 1;
  }

  /* .disburse-options .tick {
    top: 3px;
    right: 3px;
  } */

  .disburseOptions-input {
    margin: 0 0 29px 0;
  }

  .disburseOptions-input.double {
    display: flex;
    flex-direction: column;
    row-gap: 29px;
  }

  .disburseOptions-button {
    margin: 64px 0 50px 0;
  }

  /* Universal */
  .mobile-display-block {
    display: block;
  }

  .mobile-display-none {
    display: none;
  }

  .align-center {
    text-align: center;
    align-self: center;
  }

  .align-left {
    text-align: left;
    align-self: flex-start;
  }

  .align-right {
    text-align: right;
    align-self: flex-end;
  }

  /* forgot */
  .heading.forgot {
    margin: 10px 0 0 0;
  }

  .subheading.forgot {
    margin: 10px 0 11px 0;
  }

  .forgot-input {
    margin: 32px 0 0 0;
  }

  .forgot-button {
    margin: 38px 0 38px 0;
  }

  .item.highlight-light:hover {
    background-color: #ffffff;
  }

  /* Profile.js */
  #profile-user-icon {
    max-width: 375px;
  }

  #profile-connected-apps {
    column-gap: 12px;
    row-gap: 12px;
  }

  #profile-connected-apps .item {
    width: 93px;
    height: 80.7px;
  }

  #profile-connected-apps .item .vendor-logo {
    width: 65px;
  }
  /* Profile.js */

/* BlackList-Modal */
  .blacklist-modal-header {
    height: 129px;
    background-color: #FFEEEA;
    padding: 0;
    background-image: url("../images/blacklist-emoji-mobile.png");
  }

  .blacklist-modal div {
    border-radius: 27.1px;
    background-color: #FFEEEA;
  }
  #blackList-heading {
    font-size: 15.1px;
    margin-top: 13.5px;
    margin-bottom: 4.5px;
  }

  #blackList-content {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16.5px;
  }

  #blacklist-modal-logOut {
    font-size: 14px;
    margin-top: 16px;
  }
  /* BlackList-Modal */

  /* signwith style */

  #signwithPage {
    height: 100%;
  }

  #signwith-main-page {
    padding: 50px 20px 80px 20px;
  }

  #signwith-main-content {
    flex-direction: column;
    justify-content: space-between;
    height: 90vh;
    flex-wrap: nowrap;
    max-width: 400px;
    width: calc(100% - 40px);
  }

  #signwith-cards-left {
    width: auto;
  }

  .signwith-container-left {
    box-shadow: none;
    border: none;
    border-radius: none;
    background: transparent;
    padding: 0;
    flex-grow: 1;
  }

  .signwith-container-left-inner-div {
    padding: 20px;
    width: 100%;
    min-width: 100%;
  }

  .credit-interest {
    display: none;
  }

  #signwith-back-button {
    display: none;
  }

  .signwith-container-right {
    padding: 25px 20px;
    background-color: #FFFFFF;
    border-radius: 7px;
    max-width: 100%;
  }
  /* signwith style */

  .tabs-content.overflow {
    padding-right: 10px;
  }

  /* Switch Portfolio */
  .current-portfolio-mobile-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .current-portfolio-mobile {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    text-align: center;
    margin-right: 8px;
  }
  
  .current-portfolio-mobile .portfolio-icon {
    height: 19px;
    width: auto;
    margin: 9px 0 0 0;
  }

  .current-portfolio-mobile .dropdown-icon {
    width: 15px;
    height: 7.5px;
    margin-left: 5px;  
  }

  #portfolio-dropdown-mobile {
    top: 41px;
    row-gap: 5px;
  }
  /* Switch Portfolio */

  #dashboard-logo-welcome-name {
    cursor: pointer;
  }

  /* Pagination */
  .pagination li a {
    padding: 10px 12px 9px 12px;
    font-size: 10px;
    margin-left: 10px;
   }
   /* Pagination */

  /* Waitlist */
  .waitlist-image {
    min-width: 300px;
    margin-top: 20px;
  }

  .waitlist-image img {
    width: 300px;
  }

  .waitlist-button {
    margin-top: 100px;
  }
  /* Waitlist */

    /* partner Auth */
    .partnerAuth-header {
      margin: 18px 0 18px 0;
    }
  
    .partnerAuth-logo {
      margin: 30px 0 0 0;
    }
  
    .heading.partnerAuth {
      margin: 10px 0 0 0;
    }
  
    .subheading.partnerAuth {
      margin: 16px 0 30px 0;
    }
  
    .partnerAuth-input.email {
      margin: 0 0 30px 0;
    }
  
    .partnerAuth-otp-resend {
      margin: 4.8px 0 24px 0;
    }
  
    .partnerAuth-footer {
      margin-top: 30px;
    }
    /* partner Auth */

    .dashboard-refresh-button-small {
    border: none;
    border-radius: 0;
    font-size: 12px;
    padding: 0;
    align-self: start;
    margin: 0;
  }

  .dashboard-refresh-button-small .icon.zls {
    margin: 0 -2px 0 0;
    width: 22px;
  }

  /* cta-button  */
  @media only screen and (min-width: 734px) {
    .cta-button {
      font-size: 16px;
      line-height: 25px;
      padding: 10px 30px;
    }
  }

  /* Footer start */
  #footer__section {
    margin-top: 40px;
    margin-bottom: 100px;
    padding: 40px;
  }

  #footer__section .mobile-logo {
    margin: -10px 0 30px -10px;
  }

  #footer__section .links {
    column-gap: 30px;
  }

  #footer__section .links .link-column .list ul {
    margin-bottom: 25px;
  }

  #footer__section .copy-policy {
    margin-top: 10px;
    flex-direction: column;
    row-gap: 10px;
  }

  /* Notifications list */
  .notifications-icon-box {
    margin-left: 50px;
  }

  #notification-center-mobile-view {
    padding: 20px 25px;
    margin: 0 0 50px 0;
    max-width: none;
  }

}
