@font-face {
  font-family:            'Gilroy-Black';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-Black'), url('Gilroy-Black.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-BlackItalic';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-BlackItalic'), url('Gilroy-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-Bold';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-Bold'), url('Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-BoldItalic';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-BoldItalic'), url('Gilroy-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family:            'Gilroy-ExtraBold';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-ExtraBold'), url('Gilroy-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-ExtraBoldItalic';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-ExtraBoldItalic'), url('Gilroy-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-Heavy';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-Heavy'), url('Gilroy-Heavy.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-HeavyItalic';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-HeavyItalic'), url('Gilroy-HeavyItalic.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-Light';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-Light'), url('Gilroy-Light.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-LightItalic';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-LightItalic'), url('Gilroy-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-Medium';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-Medium'), url('Gilroy-Medium.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-MediumItalic';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-MediumItalic'), url('Gilroy-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-Regular';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-Regular'), url('Gilroy-Regular.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-RegularItalic';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-RegularItalic'), url('Gilroy-RegularItalic.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-SemiBold';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-SemiBold'), url('Gilroy-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-SemiBoldItalic';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-SemiBoldItalic'), url('Gilroy-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-Thin';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-Thin'), url('Gilroy-Thin.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-ThinItalic';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-ThinItalic'), url('Gilroy-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-UltraLight';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-UltraLight'), url('Gilroy-UltraLight.ttf') format('truetype');
}

@font-face {
  font-family:            'Gilroy-UltraLightItalic';
  font-style:             normal;
  font-weight:            normal;
  src:                    local('Gilroy-UltraLightItalic'), url('Gilroy-UltraLightItalic.ttf') format('truetype');
}
