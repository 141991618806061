@import url("https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200&display=swap");

:root {
  --main-color: #353535;
  --sub-color: #eef5f5;
  --minor-color: #f6e0de;
  --main-font-color: rgba(53, 53, 53, 0.5);
  --white: #ffffff;
}

html {   /* from routes/Home */
  font-size: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Gilroy-Regular';

  /* from routes/Home */
  min-height: 100vh;
  width: 100%;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

input,
select {
  -webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a {
  color: var(--main-color);
}

#authentication {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: auto;
}

#container {
  overflow: auto;
  width: 100%;
  height: auto;
}

#authentication #left {
  width: 58.8%;
  max-width: calc(100% - 623px);
  height: auto;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.illustration-1 {
  background-image: url('../images/vendor-page-illustration.png');
}

.illustration-2 {
  background-image: url('../images/get-started-illustration.png');
}

.illustration-3 {
  background-image: url('../images/withdraw-illustration.png');
}

.xmark {
  display: none;
}

.web-display-block {
  display: block;
}

.web-display-none {
  display: none;
}

#authentication #right {
  width: 41.2%;
  min-width: 623px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 160px 0 120px;
}

.typography {
  color: var(--main-font-color);
  text-align: center;
  font-size: 15px;
}

#logo {
  display: flex;
  justify-content: flex-start;
}

#logo img {
  width: 55.2px;
  height: 11.81px;
}

#header {
  width: 100%;
}

.header-back-icon {
  font-size: 20px;
  color: #00ab84;
  margin-left: -6px;
  cursor: pointer;
}

#profile.header-back-icon {
  width: 7.5px;
  height: 13px;
  margin: 25px 0 0 0;
}

.right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
  align-items: center;
  font-size: 14px;
}

#footer {
  width: 80%;
  margin: auto;
  margin-top: 50px;
  font-family: 'Gilroy-Regular';
  font-size: 12px;
  line-height: 18px;
  color: #7a7a7a;
  text-align: center;
}

.footer {
  font-size: 12px;
  line-height: 18px;
  color: #7a7a7a;
}

#input-div {
  width: 100%;
  position: relative;
}

#input-div .label {
  font-family: 'Gilroy-Regular';
  font-size: 0.85rem;
  color: #353535;
  line-height: 18px;
  margin: 0;
  text-align: left;
  height: 18px;
}

#input-div .invalid-message {
  height: 24px;
}

#input-div .label.bold {
  font-family: 'Gilroy-Semibold';
}

.input,
.select {
  position: static;
  box-sizing: content-box;
  outline: none;
  border: none;
  border-bottom: 1.5px solid #e9eaee;
  height: 48px;
  font-family: 'Gilroy-Regular';
  font-size: 14px;
  line-height: 18.2px;
  color: #353535;
  width: calc(100% - 10px);
  border-radius: 0;
  padding: 0 5px 0 5px;
  background: transparent;
}

.placeholder {
  font-family: 'Gilroy-Regular';
  font-size: 12px;
  color: red;
  background: #ffff;
  display: flex;
  align-items: center;
  text-align: center;
}

.button {
  background: #ffffff;
  border: none;
  border-radius: 6px;
  color: #fe5000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 3px;
  cursor: pointer;
  width: calc(100% - 6px);
  height: 49.92px;
  font-family: 'Gilroy-Bold';
  font-weight: 900;
  font-size: 11.65px;
  line-height: 10.35px;
  letter-spacing: -2.5%;
  -webkit-box-shadow: 0px 4px 10px rgba(142, 142, 142, 0.1);
  -moz-box-shadow: 0px 4px 10px rgba(142, 142, 142, 0.1);
  box-shadow: 0px 4px 10px rgba(142, 142, 142, 0.1);
}

.button.large {
  height: 49.92px;
}

.button.small {
  height: 43px;
}

.button.light {
  background: #FFEAE0;
}

.button.green {
  background: #00ab84;
  color: #ffffff;
}

button:disabled,
button[disabled] {
  cursor: initial;
  background: #ffffff !important;
  color: #979797;
  border: none;
}

.button-logo {
  margin-left: 3px;
}

.icon {
  width: 15px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.icon.tiny {
  width: 10px;
  height: auto;
  margin-left: 5px;
  margin-right: 5px;
}

.icon.small {
  width: 7px;
  height: auto;
  margin-left: 5px;
  margin-right: 0;
}

.icon.medium {
  width: 18px;
  margin-left: 50px;
  margin-right: 50px;
}

.heading {
  font-family: 'Gilroy-Bold';
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01rem;
  color: #353535;
  width: 100%;
}

.subheading {
  font-family: 'Gilroy-Regular';
  font-size: 12px;
  line-height: 15.2px;
  color: #7a7a7a;
  width: 100%;
}

.heading.onboarding {
  margin: 20px 0 10px 0;
}

.subheading.onboarding {
  margin: 10px 0 30px 0;
}

.onboarding-input {
  margin: 20px 0 15px 0;
}

.onboarding-button {
  margin: 25px 0 5px 0;
}

.align-left {
  text-align: left;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.divider {
  height: 25px;
  width: 1px;
  background: #ffffff;
  margin: 5px;
}

#menu {
  width: 25%;
}

@media only screen and (min-width: 770px) and (max-width: 854) {
  #menu {
    width: 27%;
  }
}

@media only screen and (min-width: 1000px) {
  #menu {
    width: 20%;
  }
}

#menu>* {
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 0;
  margin-top: 25px;
  cursor: pointer;
  font-size: 16px;
  color: var(--main-font-color);
  padding-left: 40px;
}

#menu a {
  text-decoration: none;
}

.active-menu {
  border-left: 5px solid var(--main-color);
  font-weight: 900;
  color: var(--main-color) !important;
  padding-left: 45px !important;
}

#content {
  max-height: calc(100vh - 129px);
  height: auto;
  width: calc(100% - 300px);
  word-wrap: break-word;
  width: 100%;
  overflow: auto;
  padding-right: 40px;
}

.show-sm {
  display: none;
}

.user {
  background: var(--minor-color);
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 14px;
  cursor: pointer;
}

#menu-button {
  display: none;
}

#info {
  height: 65px;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  overflow: hidden;
}

.detail {
  width: 200px;
  border-left: 1px solid var(--main-color);
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

#info>.detail:nth-child(1) {
  border-left: 0;
  align-items: flex-start;
  padding-left: 0px;
}

#info>.detail:nth-child(4) {
  align-items: flex-end;
}

.detail>div:nth-child(1) {
  font-size: 37px;
  font-weight: 700;
  color: var(--main-color);
  width: 150px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}

.detail:nth-child(1)>div:nth-child(1) {
  text-align: left;
}

.detail:nth-child(1)>div:nth-child(2) {
  width: auto;
  text-align: left;
}

.detail>div:nth-child(2) {
  font-size: 14px;
  color: #636363;
  width: 100%;
  white-space: pre;
}

#actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tabs-container {
  width: 100%;
  margin-top: 10px;
}

.tabs {
  display: flex;
  flex-direction: row;
  height: 25px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 11px;
}

.tab {
  width: auto;
  text-align: center;
  font-family: 'Gilroy-Regular';
  font-size: 14px;
  line-height: 18.2px;
  color: #353535;
  flex-shrink: 0;
  margin-right: 24px;
  cursor: pointer;
}

.no-overflow {
  overflow: hidden;
}

.active-tab {
  border-bottom: 2px solid #fe5000;
  font-weight: bolder;
  color: #353535;
  padding-bottom: 23px;
}

.tabs-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 10px;
  max-height: 330px;
}

.tabs-content::-webkit-scrollbar {
  width: 5px;
}

.tabs-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.tabs-content::-webkit-scrollbar-thumb {
  background: #888;
}

.tabs-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tabs-content::-webkit-scrollbar-button {
  display: none;
}


@media screen and (max-width: 450px) {
  .tabs-content {
    max-height: 100%;
    overflow-y: auto;
  }
}

#datatable {
  width: 100%;
  margin-top: 39px;
  border-collapse: collapse;
  table-layout: fixed;
}

thead tr {
  background: #d5e2e4;
  color: #8ba5a6;
  height: 42px;
}

tr {
  background: #ffffff;
  color: var(--main-color);
  height: 70px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: -0.02em;
  border-radius: 10px;
  text-align: center;
}

tr:hover {
  background: #eef5f5;
}

td {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.refresh {
  cursor: pointer;
}

.activity-status {
  width: fit-content;
  height: 20px;
  border-radius: 16px;
  font-family: 'Gilroy-Regular';
  font-size: 12px;
  line-height: 16.8px;
  padding: 1.5px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.issued,
.repaid,
.completed {
  background: #51b57c33;
  color: #0078a7;
}

.serviced {
  background: #6bccfe33;
  color: #6bccfe;
}

.disbursed,
.pending {
  background: #ff9f1933;
  color: #ff9f19;
}

.requested,
.processing,
.disbursing {
  background: #9aeafc;
  color: #0078a7;
}

.rejected {
  background: #ffcede;
  color: #ff0057;
}

.cancelled,
.liquidated {
  background: #d0000033;
  color: #d00000;
}

#overlay {
  height: 100%;
  width: 480px;
  padding-right: 46px;
  padding-left: 46px;
  background: var(--main-color);
  color: #ffffff;
  position: fixed;
  right: 0;
  top: 0;
  overflow: hidden;
  overflow-y: auto;
  z-index: 100000000000000;
}

#overlay .text {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 11px;
  margin-top: 20px;
}

#overlay .subheading,
#overlay a {
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin-top: 20px;
  opacity: 0.85;
}

#close {
  display: block;
  margin-top: 50px;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
}

#loan {
  width: auto;
  height: 279px;
  margin-top: 40px;
  background: #eef5f5;
  box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  overflow: hidden;
  padding-top: 35px;
}

#loan .typography {
  font-size: 16px;
  color: #353535;
}

#loan input {
  display: block;
  width: 230px;
  margin-top: 71px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#legal input {
  width: 100%;
  margin-top: 30px;
  background: transparent;
  color: var(--sub-color);
  height: 60px;
  border: 1px solid var(--sub-color);
  box-sizing: border-box;
  border-radius: 4px;
}

#application-successful {
  width: auto;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-top: calc((100vh - 468px) / 3);
}

#application-successful #successful-logo-container {
  min-height: 322px;
}

#application-successful #successful-logo {
  text-align: center;
  width: 322px;
}

.heading.successful {
  text-align: center;
  margin: auto;
}

.subheading.successful {
  text-align: center;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 31px;
  max-width: 250px;
}

#successful-button {
  width: 100%;
}

.slider-container {
  display: inline-block;
  width: 70%;
  margin-top: 50px;
  margin-left: 15%;
  position: relative;
}

.slider-minimum,
.slider-maximum {
  position: absolute;
  margin-top: 15px;
}

.slider-minimum {
  left: 0;
}

.slider-maximum {
  right: 0;
}

.track {
  height: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.track-0 {
  background: var(--main-color);
}

.track-1 {
  background: #f9fafd;
}

.thumb {
  background: var(--main-color);
  width: 32px;
  height: 32px;
  border-radius: 32px;
  margin-top: -16px;
  cursor: pointer;
}

.spread-button {
  width: 315px;
  height: 46px !important;
  display: block;
  margin: auto;
  background: #8ba5a6 !important;
  margin-top: 41px;
}

.full-button {
  margin-top: 28px;
  width: 316px;
}

.full-button button {
  margin-top: 0 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff !important;
  background: #8ba5a6 !important;
}

.next-image {
  height: 14px;
  margin-left: 8px;
  width: 14px;
}

.notification {
  background: #ffffff;
  color: #353535;
  box-shadow: 0px 0.5px 2px 0 #000;
  padding: 14.67px;
  line-height: 130%;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  border-radius: 10px;
  max-width: 583px;
}

.notification-img {
  margin-right: 14.67px;
}

.notification-close {
  align-self: start;
  margin-left: 14.67px;
  cursor: pointer;
}

.notification-title {
  font-family: 'Gilroy-Bold';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #333333;
}

.notification-content {
  font-family: 'Gilroy-Regular';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #4D4D4D;
}

.back {
  align-self: flex-start;
  margin-left: 30px;
  margin-bottom: -20px;
}

.repayment-back {
  display: flex;
  align-self: flex-start;
  margin-left: 0;
  margin-top: 40px;
  margin-bottom: 34px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.back img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

#keypad {
  background-color: #fff !important;
  color: #353535 !important;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-family: 'Gilroy-Regular';
  font-style: normal;
  font-weight: normal;
  margin: 29.4px 0 0 0;
}

@media screen and (max-width: 350px) {
  #keypad {
    min-width: 280px;
  }
}

#keypad #header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

#keypad .back {
  margin-top: 20px;
}

.heading.keypad {
  margin: 0 0 25px 0;
}

#keypad-dashboard {
  font-size: 15px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 25px;
  text-decoration: underline;
  position: relative;
  float: right;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  position: relative;
  top: -25px;
  margin-right: 35px;
}

#keypad-dashboard a {
  color: #353535;
}

#keypad-amount {
  font-size: 12px;
  line-height: 18px;
  color: #7a7a7a;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  height: 18px;
  margin-bottom: 20px;
}

#keypad-amount p {
  color: #00ab84;
}

#no_placeholder_input {
  min-width: 99px;
  width: 50px;
  margin-top: -17px;
}

#keypad-value {
  font-family: 'Gilroy-SemiBold';
  font-size: 46.1px;
  line-height: 54px;
  color: #353535;
  width: 100%;
  height: 56.8px;
  border-bottom: 1px solid #e9eaee;
  margin: 6px 0 4px 0;
}

.keypad-column {
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 246.2px;
  height: 62.17px;
  margin-bottom: 14.17px;
}

.keypad-row {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Gilroy-SemiBold';
  font-style: normal;
  font-size: 26.56px;
  line-height: 15.94px;
  text-align: center;
  letter-spacing: -0.01px;
  color: #353535;
  height: 62.17px;
  width: 62.17px;
  cursor: pointer;
  border-radius: 100%;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  border: 1px solid #fe5000;
  transition: transform 0.1s;
}

@media (hover: hover){
  .keypad-row:hover {
    background-color: #FFEAE0
  }
}

.keypad-row:active {
  background-color: #FFEAE0;
  box-shadow: 0 0px #666;
  transform: translateY(2px);
}

.keypad-row:nth-child(1),
.keypad-row:nth-child(4),
.keypad-row:nth-child(7),
.keypad-row:nth-child(10) {
  position: relative;
}

.keypad-row:nth-child(3),
.keypad-row:nth-child(6),
.keypad-row:nth-child(9),
.keypad-row:nth-child(12) {
  position: relative;
}

.keypad-row.no-border {
  border: 0 !important;
}

#keypad-full-button {
  width: 100%;
  align-self: center;
  margin-top: 3.83px;
}

.keypad-label {
  font-size: 13px;
  line-height: 18px;
  color: #353535;
}

#keypad-max {
  font-family: 'Gilroy-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #353535;
  margin-top: 24px;
  cursor: pointer;
}

.white {
  background: #f9fafd !important;
  color: #353535 !important;
  font-weight: 500 !important;
}

.withdrawal-page-separator {
  margin-top: 27px;
}

.radio-group {
  margin-top: 15px;
}

.radio-group>div {
  margin-bottom: 15px;
}

.radio-button {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 2px;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  position: relative;
  top: 1.2px;
}

.radio-button>div {
  background: #fff;
  border-radius: 0.818182px;
}

.radio-button.active>div {
  background: #353535;
  border-radius: 0.818182px;
  margin: 2px;
  width: 9px;
  height: 9px;
}

#withdrawal {
  background: #8ba5a6;
  color: #f9fafd;
  overflow: scroll;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-family: 'Gilroy-Regular';
  font-style: normal;
  font-weight: normal;
}

#withdrawal .back {
  margin-top: 30px;
  margin-bottom: 20px;
}

.picker-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  padding-left: 0;
  padding-bottom: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.picker-options>span {
  width: 28.88px !important;
  height: 28.88px !important;
  border-radius: 28.88px !important;
}

.picker-options>div {
  padding-left: 20px;
}

.withdrawal-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  cursor: pointer;
}

.withdrawal-options:hover,
.withdrawal-options:active {
  background: #93adae;
}

.withdrawal-options>div {
  margin-left: 30px;
}

.placeholder {
  font-family: 'Gilroy-Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #353535;
  background: #f0f2f7;
  border-radius: 4px;
  height: 60px;
  margin-top: 19px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  min-width: 70%;
}

#loading {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

#loading img {
  width: 30%;
}

#empty {
  width: 575px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

#empty label {
  font-family: 'Gilroy-Light';
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #353535;
}

.loading {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#rates {
  background-color: #00ab84;
  color: #fff;
  font-family: 'Gilroy-Regular';
  font-size: 14.72px;
  line-height: 19.13px;
  font-weight: normal;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-notice {
  background-color: rgb(211, 3, 3);
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  padding: 5px;
  margin-top: 50px;
  display: inline-block;
}

.full-width {
  width: 100%;
}

.repayment-goto {
  margin-top: 20px;
}

.infoTypography {
  padding-top: 10px;
}

.infoTypography .typography {
  text-align: left;
}

/* Signin */
.heading.signin {
  margin: 44px 0 0 0;
}

.subheading.signin {
  margin: 7px 0 20px 0;
}

.signin-input {
  margin: 22px 0 0 0;
}

.signin-button {
  margin: 36px 0 32px 0;
}

.footer.signin {
  text-align: center;
  margin-top: 19px;
}

.signup-button {
  margin: 30px 0 0 0;
}

.footer.signup {
  text-align: left;
  margin: 40px 3px 60px 3px;
}

.footer.signup img {
  margin-bottom: 3px;
}

.double_input {
  width: 100%;
  display: flex;
  flex-direction: row;
}


.mobile_code_select {
  min-width: 99px;
  width: 45%;
  position: relative;
}

.select-dropdown-icon {
  display: block;
  width: 8px;
  height: 5px;
  position: absolute;
  margin: 0;
  right: 10%;
  top: 40px;
  z-index: 1;
}

.password_input_show {
  display: flex;
  position: relative;
}

.password_show_icon {
  font-size: 10px;
  margin: 0;
  position: absolute;
  right: 10px;
  top: 49%;
  cursor: pointer;
  background-color: #FEF0ED;
  color: #FE5000;
  padding: 2px 10px;
  border-radius: 10px;
}

.remember-me-label {
  font-family: 'Gilroy-Light';
  font-size: 13px;
  margin: 3px 0 0 -8px;
  color: #7a7a7a;
}

/* User Agreement */
.agreementDiv {
  background-color: #f9f8f7;
  padding-top: 100px;
}

.agreementDiv h1 {
  text-align: center;
  padding-top: 2%;
}

.agreementDiv p {
  text-align: left;
}

.agreementDiv h4 {
  text-align: left;
}

.agreementDiv p a {
  text-decoration: none;
  color: #fe5000;
}

.agreementDiv .nav-links {
  width: 30%;
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.agreementDiv .newStyle {
  text-decoration: none;
  font-weight: bold;
}

@media all and (max-width: 500px) {
  .agreementDiv .text {
    margin-left: 20px;
    margin-right: 15px;
  }

  .agreementDiv .nav-links #use {
    margin-left: 70px;
  }
}

@media all and (max-width: 800px) and (min-width: 501px) {
  .agreementDiv .text {
    margin-left: 20px;
    margin-right: 15px;
  }
}

@media all and (max-width: 1023px) and (min-width: 801px) {
  .agreementDiv .text {
    margin-left: 20px;
    margin-right: 15px;
  }
}

@media all and (min-width: 1024px) {
  .agreementDiv .text {
    margin-left: 200px;
    margin-right: 170px;
  }
}

/* Dashboard */
.dashboard-activity.web-display-block {
  display: block;
}

.dashboard-activity.web-display-none {
  display: none;
}

#dashboard-header-parent {
  width: 100%;
  height: 69px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(191, 191, 191, 0.25);
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

#dashboard-header {
  flex-grow: 1;
  max-width: 1357px;
  height: 69px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding: 0 36px 0 33px;
  cursor: pointer;
}

.dashboard-header-logo {
  width: 71px;
}

#dashboard-header-items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 18px;
  align-items: center;
  /* height: auto; */
  font-family: 'Gilroy-Medium';
  font-weight: 900;
  font-size: 16px;
  line-height: 25.2px;
  color: #121E0E;
}

#dashboard-header-items a:hover {
  color: #FE5000;
}

#dashboard-header-items .vertical-divider {
  background-color: #000000;
  width: 2px;
  height: 45px;
}

#dashboard-name-header {
  width: 285.5px;
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* margin: auto; */
  /* padding: 0 36px; */
}

#dashboard-logo-welcome-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#dashboard-carrot-logo-mark {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  background-color: #fe5000;
  text-align: center;
  margin-right: 5px;
  padding-top: 3.5px;
}

#dashboard-carrot-logo-mark img {
  height: 18px;
  width: 24px;
  margin: 9px 0 0 0;
}

#dashboard-carrot-logo-mark b {
  font-family: 'Gilroy-SemiBold';
  color: #ffffff;
  font-size: 21px;
  /* margin-left: 1px; */
}

#dashboard-welcome {
  font-family: 'Gilroy-Regular';
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #979797;
}

#dashboard-user-name {
  font-family: 'Gilroy-Bold';
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #353535;
}

#dashboard-refresh-button {
  width: 139px;
}

.icon.zls {
  margin: 0;
  cursor: pointer;
}

#dashboard-page {
  height: 100vh;
  background-color: #FFFBF9;
  background-image: url(../images/mobile-background.svg);
}

#dashboard {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 0px;
  margin: 0;
  padding: 20px 36px 0 36px;
}

#dashboard-cards-left {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  overflow: auto;
  padding: 10px;
}

#dashboard-cards-upper {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

#dashboard-card-upper-balance {
  width: 285.5px;
  height: 111px;
  border-radius: 6px;
  box-shadow: 0px 2px 10px rgba(191, 191, 191, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 4px;
  padding: 0 18px;
}

#dashboard-cards-left *::-webkit-scrollbar {
  background-color: transparent;
  display: none;
}

#dashboard-card-upper-balance .text-line {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 3px;
}

#dashboard-card-upper-balance .text-line-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#dashboard-card-upper-balance .text-line-bottom b {
  font-family: 'Gilroy-Bold';
}

#dashboard-card-upper-balance .text {
  font-family: 'Gilroy-Regular';
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #ffffff;
  text-align: center;
}

.black {
  color: #000000 !important;
}

.text-white {
  color: #ffffff !important;
}

.underline {
  cursor: pointer;
  text-decoration: underline;
}

.dashboard-info-icon {
  margin: 0 0 2px 6px;
  width: 10.8px;
  height: 10.8px;
  cursor: pointer;
}

.input-error-icon {
  margin: -2px 5px 0 0;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.input-error-text {
  font-family: 'Gilroy-Regular';
  font-size: 0.85rem;
  line-height: 18px;
  color: #fe5000;
  padding-top: 2px;
}

.icon-white {
  color: #ffffff;
}

.icon-black {
  color: #000000;
}

.icon-green {
  color: #00ab84;
}

.icon-error {
  color: #fe5000;
}

#dashboard-card-upper-balance .value-line-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#dashboard-card-upper-balance .value-line {
  display: flex;
  flex-direction: row;
}

#dashboard-card-upper-balance .value-icon {
  height: fit-content;
  width: fit-content;
  align-self: flex-end;
  margin-bottom: 1px;
}

#dashboard-card-upper-balance .dashboard-info-icon {
  margin-bottom: 0;
  margin-left: 3px;
}

#dashboard-card-upper-balance .value {
  font-family: 'Gilroy-Bold';
  font-size: 25px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #ffffff;
  overflow-x: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: none !important;
  /* width: fit-content; */
}

#dashboard-card-upper-details {
  background-color: #ffffff;
  width: 285.5px;
  height: 130px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 2px 10px rgba(191, 191, 191, 0.25);
  padding: 15px 0;
}

#dashboard-card-upper-details .item {
  width: 117px;
}

#dashboard-card-upper-details .text {
  font-family: 'Gilroy-Regular';
  font-size: 11px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #7a7a7a;
}

#dashboard-card-upper-details .value {
  font-family: 'Gilroy-Regular';
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -2%;
  color: #353535;
  margin-top: 3.93px;
  overflow-x: scroll;
  /* padding-bottom: 5px; */
  scrollbar-color: transparent transparent !important;
  scrollbar-width: none !important;
}

#dashboard-cards-lower {
  display: flex;
  flex-direction: row;
  column-gap: 36px;
  row-gap: 37px;
}

#dashboard-cards-lower-actions {
  width: 285.5px;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  box-shadow: 0px 2px 10px 0px #BFBFBF40;
  border-radius: 7px;
  background: #ffffff;
}

.dashboard-action-card {
  width: fit-content;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-action-card img {
  width: 24px;
  height: 20px;
}

.dashboard-action-card .title {
  font-family: 'Gilroy-Medium';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #353535;
  margin-top: 5px;
}

.action-card-circle {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-card-circle.background-yellow {
  background: #FFF7E9;
}

.action-card-circle.background-blue {
  background: #E6EDFD;
}

.action-card-circle.background-purple {
  background: #EBE4FC;
}

.action-card-circle.background-pink {
  background: #FBEBEA;
}

.action-card-circle.background-green {
  background: #E3FFF9;
}

.action-card-circle.background-light-green {
  background: #F0FFE9;
}

.action-card-circle.background-orange {
  background-color: #FFEEEA
}

#dashboard-cards-lower-portfolio {
  width: 285.5px;
  height: 377px;
  border-radius: 6px;
  box-shadow: 0px 2px 10px rgba(191, 191, 191, 0.25);
  position: relative;
}

.graph-text {
  font-family: 'Gilroy-Medium';
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  color: #353535;
}

.graph-abs {
  width: 100%;
  position: absolute;
  top: 45%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.graph-abs p {
  font-family: 'Gilroy-Bold';
  font-size: 30.6px;
  line-height: 36px;
  max-width: 185px;
  overflow-x: auto;
  scrollbar-color: transparent transparent !important;
  scrollbar-width: none !important;
}

.graph-label {
  font-family: 'Gilroy-Regular';
  font-size: 12px;
  line-height: 14px;
  color: #353535;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.graph-dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 5px;
}

.graph-dot.credit {
  background: linear-gradient(#ff9f69, #87ab86);
}

.graph-dot.balance {
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 13px;
}

#dashboard-recent-right {
  width: auto;
  height: auto;
  /* max-height: 548px; */
  display: flex;
  flex-direction: column;
  padding: 8px 0 0 0;
}

#dashboard-recent-heading {
  align-self: flex-start;
  font-family: 'Gilroy-Medium';
  font-weight: 900;
  font-size: 14px;
  line-height: 18.2px;
  letter-spacing: -2%;
  margin: 0 0 0 10px;
}

.dashboard-activity {
  width: calc(100% - 20px);
  min-width: 575px;
  border-radius: 6px;
  box-shadow: 0px 2px 10px rgba(191, 191, 191, 0.25);
  margin: 5px 10px 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #ffffff;
}

.dashboard-activity .upper {
  min-height: 73px;
}

.dashboard-activity .lower {
  margin: 0 23px 20px 34px;
}

.no-gutters {
  padding: 0;
}

.no-gutters-right {
  padding-right: 0;
}

.no-gutters-left {
  padding-left: 0;
}

.dashboard-activity img.recent-icon {
  width: 34px;
  height: 34px;
  margin-right: 10px;
  margin-left: 10px;
}

.activity-icon-item {
  display: flex;
  flex-direction: row;
}

.activity-action-items {
  display: flex;
  flex-direction: column;
}

.activity-action-items.rep-ref {
  text-align: end;
}

.activity-action-items.rep-ref.single {
  text-align: start;
}

.activity-default-text {
  font-family: 'Gilroy-Regular';
  font-size: 14px;
  line-height: 18.2px;
  letter-spacing: -0.02em;
  color: #353535;
}

.activity-default-text.not-black {
  color: #35353580;
}

.activity-default-text.bold {
  font-family: 'Gilroy-SemiBold';
}

.activity-default-text.repay {
  color: #35353580 !important;
}

.activity-default-text.repay u {
  opacity: 1;
  color: #00ab84;
  font-family: 'Gilroy-Medium';
}

.activity-default-text.refresh {
  margin-top: 6px;
  color: #35353580 !important;
}

.activity-default-text.refresh.single {
  margin-top: 0;
}

.activity-default-text.refresh u {
  margin-top: 6px;
  color: #ff9f19;
  font-family: 'Gilroy-Medium';
}

.activity-default-text.cancel {
  color: #35353580 !important;
  text-align: end;
}

.activity-default-text.cancel u {
  opacity: 1;
  color: #fe5000;
  cursor: pointer;
}

.activity-default-text.value {
  max-width: 100px;
  overflow-x: scroll;
  font-family: 'Gilroy-Regular';
  scrollbar-color: transparent transparent !important;
  scrollbar-width: none;
}

.activity-default-text.value::-webkit-scrollbar {
  background-color: transparent;
  display: none;
}

.activity-default-text.value.ref {
  margin: auto;
}

.activity-dropdown-icon {
  padding: 0;
  margin: 0 10px 2px 0;
  cursor: pointer;
  text-align: end;
}

.heading.addCard {
  margin: 45.6px 0 68px 0;
}

/* modal */
.modal-backdrop {
  width: 100%;
  height: 100%;
}

.modal-content {
  margin: auto;
  border-radius: 29.1787px;
  text-align: center;
  width: 366px;
  height: 450px;
}

.modal-body {
  padding: 0 27px;
}

.modal-body img {
  width: 138px;
  height: 112px;
}

.modal-body b {
  font-family: 'Gilroy-SemiBold';
  font-weight: 900;
  font-size: 17px;
  line-height: 25.2px;
}

.modal-body p {
  font-family: 'Gilroy-Regular';
  font-size: 15.6px;
  line-height: 21.7px;
  color: #7a7a7a;
}

.modal-header {
  border-bottom: 0;
  height: 38.5px;
}

button.btn-close {
  font-family: 'Gilroy-ExtraBold';
  color: #000000;
  font-size: 12px;
}

.heading.offer {
  margin: 10px 0 0 0;
}

.offer-amount-container {
  height: 56.8px;
  margin: 14.4px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.offer-amount {
  font-family: 'Gilroy-SemiBold';
  font-size: 46.08px;
  line-height: 54px;
  color: #353535;
}

.offer-amount-refresh .icon.zls {
  width: 24px;
  margin: 0 0 -9px 5px;
}

.offer-full-button {
  margin: 25.6px 0 0 0;
}

.offer-info {
  cursor: pointer;
  color: #353535;
  font-family: 'Gilroy-Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 11.25px;
  line-height: 15.31px;
  margin-top: 36.06px;
}

.fi-info {
  color: #00ab84;
  margin: -2px -2px 0 0;
  width: 10.25px;
}

*:focus {
  outline: none;
  text-decoration: none;
}

.footer.offer {
  margin-top: 30px;
}

@media screen and (max-width: 992px) {
  .modal-content {
    margin: auto 60px;
  }
}

/* select vendor */
.heading.vendor {
  margin: 35px 0 0 0;
}

.subheading.vendor {
  margin: 6.4px 0 0 0;
}

.vendor-cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
  padding: 5px;
  margin: 31.8px 0 0 0;
}

.vendor-cards .item {
  width: 95px;
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(191, 191, 191, 0.25);
  border-radius: 4.8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  position: relative;
}

.vendor-cards .item .vendor-logo {
  width: 70px;
}

.vendor-cards .tick {
  position: absolute;
  top: 6px;
  right: 6px;
  background: transparent;
}

.vendor-button {
  margin: 36.8px 0 0 0;
}

/* repay method */
.heading.repayMethod {
  margin: 35px 0 0 0;
}

.subheading.repayMethod {
  margin: 6.4px 0 0 0;
}

.repayMethod-options {
  display: flex;
  flex-direction: column;
}

.repayMethod-options .item {
  font-family: 'Gilroy-Light';
  font-weight: 700;
  font-size: 14.56px;
  line-height: 18.93px;
  color: #7a7a7a;
  width: 100%;
  height: 51px;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  column-gap: 14px;
  align-items: center;
  padding: 0 10px 0 0;
  margin: 25px 0 0 0;
  cursor: pointer;
}

.repayMethod-button {
  margin: 36.8px 0 0 0;
}

/* Universal */
.web-display-block {
  display: block;
}

.web-display-none {
  display: none;
}

/* withdrawal and repayment Page */
.heading.disburseOptions {
  margin: 26.6px 0 0 0;
}

.subheading.disburseOptions {
  margin: 9.6px 0 15.2px 0;
}

.disburse-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  margin: 0 0 19px 0;
  row-gap: 15.2px;
}

.disburse-options .item {
  width: 100%;
  height: 51px;
  border-radius: 6px;
  padding: 0 0 0 18.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8.1px;
  background-color: #FFFFFF;
  font-family: 'Gilroy-Regular';
  font-size: 11.2px;
  line-height: 15.1px;
  margin: 0;
  color: #7A7A7A;
  position: relative;
  background: transparent;
  border: 0.5px solid #ededed;
}

.disburse-options .tick {
  position: absolute;
  top: 19px;
  right: 14.5px;
  background: transparent;
}

.currency-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 5px;
}

.item.highlight:focus,
.item.highlight:hover {
  background-color: #fe5000;
  color: #ffffff !important;
}

.account-options-active {
  background-color: #fe5000 !important;
  color: #ffffff !important;
}

.item.highlight-light:hover {
  background-color: #FFEAE0;
  color: #ffffff !important;
}

.disburseOptions-input {
  margin: 0 0 10px 0;
}

.account-options-button-content {
  max-width: 75%;
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-color: transparent transparent !important;
  scrollbar-width: none;
}

.disburseOptions-input.double {
  display: flex;
  flex-direction: row;
  column-gap: 35px;
}

.disburseOptions-button {
  margin: 0 0 50px 0;
}

/* dollarRepaymentPage */
.disburse-options.dollar {
  margin-bottom: 29px;
}

.details-background {
  height: auto;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ededed;
  margin-bottom: 75px;
  padding: 0 21.6px 21.6px 0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 1.6px;
}

.details-content {
  margin-top: 21.6px;
  margin-left: 14px;
  color: #353535;
  letter-spacing: -0.0016em;
}

.details-content p {
  font-family: 'Gilroy-Regular';
  font-size: 12.8px;
  margin-bottom: -5px;
}

.details-content span {
  font-family: 'Gilroy-Bold';
  font-size: 12.8px;
  line-height: 21px;
}

.details-content.car-green {
  color: #00ab84;
}

.details-content.car-orange {
  color: #fe5000;
}

.details-content.note {
  font-family: 'Gilroy-Regular';
}

.details-content.note p {
  font-family: 'Gilroy-Light';
  background: #51b57c;
  color: #ffffff;
  width: 44px;
  height: 21px;
  text-align: center;
  border-radius: 4px;
  padding: 2px;
  margin: 0;
}

.details-content.note span {
  /* font-size: 11.2px; */
  font-family: 'Gilroy-Regular';
  /* line-height: 16.8px; */
}

/* forgot */
.heading.forgot {
  margin: 20px 0 0 0;
}

.subheading.forgot {
  margin: 7px 0 17.2px 0;
  line-height: 20px;
}

.forgot-input {
  margin: 20px 0 0 0;
}

.forgot-button {
  margin: 31px 0 31px 0;
}

.footer.forgot {
  margin-bottom: 150px;
  cursor: pointer;
  text-align: center;
  margin-top: 19px;
}

.forgot-otp-resend {
  width: 100%;
  font-family: 'Gilroy-Light';
  font-size: 12px;
  line-height: 18px;
  margin: 4.8px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 18px;
}

.forgot-otp-resend p {
  color: #00ab84;
}

#otp-refresh-button {
  width: 120px;
  margin: auto;
}

#otp-refresh-button button {
  height: 35px;
}

.resend-otp-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 5px 0 0 0;
}

.resend-otp-button {
  width: fit-content;
  font-family: 'Gilroy-Light';
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  background-color: #f0fff6;
  color: #00AB84;
  padding: 2px 10px;
  border-radius: 10px;
}

.resend-otp-button:hover {
  background-color: #E3FFEE;
}

.subheading.otp-sent {
  text-align: center;
}

/* dashboard tooltip */
.tooltip-inner {
  background-color: #00ab84;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  letter-spacing: 0.02em;
  line-height: 130%;
  font-size: 14px;
  opacity: 1;
}

.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #00ab84;
}

.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #00ab84;
}

.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #00ab84;
}

/* Home and Blog */

/* Footer start */
#footer__section {
  background-color: #FDEBE7;
  border-radius: 30px;
  padding: 90px 20% 50px 10%;
  margin-top: 60px;
  margin-bottom: 80px;
}

#footer__section a {
  text-decoration: none;
}

#footer__section .links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

#footer__section .links .link-column {
  display: flex;
  flex-direction: column;
}

#footer__section .links .link-column .title-vert {
  background-color: #fe5000;
  border: none;
  width: 1.2px;
  height: 18px;
}

#footer__section .links .link-column .title {
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #4A4A4A;

  display: flex;
  flex-direction: row;
  column-gap: 17px;
  margin-bottom: 10px;
}

#footer__section .links .link-column .list ul {
  font-family: 'Gilroy-Regular';
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #4A4A4A;
  padding: 0;
}

#footer__section .links .link-column .list ul li {
  list-style-type: none;
  cursor: pointer;
  margin-left: 19px;
  margin-bottom: 5px;
}

#footer__section .copy-policy {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 80px;

  font-family: 'Gilroy-Regular';
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #4A4A4A;
}

/* Footer end */

/* getStarted */
#get-started-section {
  width: 100%;
  height: auto;
  background-color: #FFEEEA;
  padding-top: 10%;
  border-radius: 40px;
}

#get-started-section .heading-text {
  text-align: center;
}

.get-started-image {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10% 0;
}

.get-started-image img {
  width: 85%;
  height: auto;
  max-width: 460px;
}

.get-started-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 15%;
}

.formHeight {
  height: 63px;
}

.formHeight input {
  height: 63px;
}

.formWidth {
  width: calc(100vw - 70px);
  min-width: 200px;
  max-width: 411px;
}

.textAreaHeight {
  height: 130px;
}

.paddingTopNBottom {
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  padding-left: 2.1rem;
  padding-right: 1.8rem;
  color: #73767a;
  text-align: start;
}

.paddingTopNBottom:focus {
  box-shadow: none;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 5000s;
  background: transparent !important;
}

.marginTopNBottom {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.extraBottomMargin {
  margin-bottom: 0.8rem;
}

.formBorderRadius {
  border-radius: 6.25rem;
}

.textAreaBorderRadius {
  border-radius: 12px;
}

.whiteBackground {
  background-color: #ffff;
}

.noBorderOutline {
  border: 0;
  outline: 0;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  background: transparent;
}

*:focus {
  outline: none;
  text-decoration: none;
}

#get-started-section .cta-button {
  height: 63px;
  width: calc(100vw - 70px);
  min-width: 200px;
  max-width: 411px;
  margin-top: 9px;
  margin-bottom: 1rem;
  font-size: 16px;
}

#dropdownMenuButton1 {
  position: relative;
}

.checkForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.form-check-label {
  font-family: "Gilroy-Medium";
  font-size: 0.8rem;
  line-height: 1.3rem;
  color: #5e5e5e;
  padding: 3px 0 0 6px;
  justify-content: center;
}

.checkForm input {
  font-size: 1.25rem;
  line-height: 1rem;
  background: transparent;
  border: 1px solid #FE5000;
  margin-bottom: 5px;
}

.checkForm input:checked {
  border-color: #FE5000;
  background: #FE5000;
  transition: 250ms;
}

.checkForm input:focus {
  box-shadow: none;
  border-color: #FE5000;
}

.terms {
  padding-left: .62rem;
  padding-right: .62rem;
  margin-top: .31rem;
  font-family: "Gilroy-Medium";
  font-size: 0.8rem;
  line-height: 1.3rem;
  color: #5e5e5e;
}

.terms a {
  color: #5e5e5e;
  cursor: pointer;
}

.bi-chevron-down {
  position: absolute;
  top: auto;
  bottom: auto;
  right: 7%;
  background: none;
  font-size: 0.93rem;
  font-weight: 900;
  color: #767675;
}

textarea {
  resize: none;
}

ul.dropdown-menu {
  width: 100%;
  padding-left: 1rem;
}

@media only screen and (min-width: 420px) {
  .get-started-input {
    padding-bottom: 8%;
  }
}

@media only screen and (min-width: 734px) {
  #get-started-section .heading-text {
    text-align: left;
    padding-left: 10%;
  }

  #get-started-section {
    padding-top: 9%;
  }

  .get-started-input {
    height: auto;
    max-width: 55vw;
    flex-grow: 1;
    align-self: center;
    padding: 3rem 2% 5rem 2%;
    align-items: flex-start;
  }

  .get-started-image {
    max-width: 45vw;
    padding: 5px 0 40px 0;
  }

  .get-started-image img {
    width: 90%;
    height: auto;
    padding: 1%;
  }

  .get-started-input {
    padding-bottom: 7%;
  }

  .get-started-content {
    display: flex;
  }

  .formHeight input {
    height: 63px;
  }

  .formHeight {
    height: 63px;
  }

  .formWidth {
    width: calc(0.4 * 100vw);
    min-width: 350px;
    max-width: 500px;
  }

  .paddingTopNBottom {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.68rem;
    color: #73767a;
    text-align: start;
  }

  .marginTopNBottom {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }

  #get-started-section .cta-button {
    height: 62px;
    width: calc(0.4 * 100vw);
    min-width: 350px;
    max-width: 500px;
    margin-top: 0.64rem;
    margin-bottom: 0.8rem;
  }

  .bi-chevron-down {
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 1000px) {
  #get-started-section {
    padding-top: 7%;
  }

  .get-started-image img {
    width: 85%;
  }
}

/* Navbar start */
.navbar {
  background-color: transparent !important;
  margin: 30px 0;
  /* position: absolute !important; */
  /* top: 0; */
  /* padding: 0 18px 0 30px; */
  padding: 0;
  width: 100%;
}

.navbar-brand {
  margin: 0;
  padding-top: 11px;
}

.navbar-toggler {
  outline: none;
  border: none;
}

.midNavItem {
  margin: auto;
}

.icon-black {
  color: #121e0e;
}

.navButton .cta-button {
  width: fit-content;
  /* height: calc(0.9 * 60px); */
  margin: 0 0 0 35px;
  padding: 10px 45px;
}

.navBtnBorderRadius {
  border-radius: 30px;
}

.noBorderOutline {
  border: 0;
  outline: 0;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  box-shadow: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.buttonBackground {
  background-color: #fe5000;
}

/* .navbar-nav li {} */
a.nav-link,
div.nav-link {
  font-family: "Gilroy-SemiBold";
  color: #121e0e !important;
  font-size: 16px;
  line-height: 25px;
  margin-left: 35px;
  margin-right: 0px;
  cursor: pointer;
}

a.nav-link:hover,
div.nav-link:hover {
  text-decoration: underline;
}

.navButton .cta-button {
  font-size: 16px;
  line-height: 25px;
}

@media only screen and (min-width: 734px) {
  /* .navbar {
    padding-left: calc(0.45 * 155px);
    padding-right: calc((0.45 * 155px) - 12px);
  } */

  .navbar-collapse {
    right: 12px !important;
    top: 10px;
  }

  a.nav-link,
  div.nav-link {
    font-size: 18px;
    line-height: 25px;
  }

  .navButton .cta-button {
    font-size: 18px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 991px) {
  .navbar {
    margin: 25px 0;
  }

  .navbar-collapse {
    position: absolute;
    right: 12px;
    top: 10px;
    background-color: #ffffff;
    height: auto;
    padding: 0 0 15px 0;
    border-radius: 10px;
    width: 300px;
  }

  .navbar-toggler {
    z-index: 1000000;
  }

  a.nav-link,
  div.nav-link {
    padding: 15px 0;
  }

  .navButton .cta-button {
    width: fit-content;
    height: calc(0.75 * 60px);
  }
}

@media only screen and (max-width: 545px) {
  .navbar-collapse {
    height: auto;
    padding: 0 0 15px 0;
    width: calc(100% - 60px);
    min-width: 165px;
  }
}

@media only screen and (min-width: 992px) {
  .navbar-brand {
    padding-top: 0;
  }
}
/* Navbar end */

/* navbar wrap start */
.navbar-wrap > .navbar {
  position: absolute !important;
  top: 0;
  padding: 0 18px 0 30px;
}

@media only screen and (min-width: 734px) {
  .navbar-wrap > .navbar {
    padding-left: calc(0.45 * 155px);
    padding-right: calc((0.45 * 155px) - 12px);
  }
}
/* navbar wrap end */

/* Section1 */
#section-1 {
  min-height: 80vh;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: #FFFFFF;
}

#section-1.landing {
  background-image: url("../images/hero-section-banner.png");
}

#section-1.business {
  background-image: url("../images/business-hero-illustration.png");
}

#section-1.individual {
  background-image: url("../images/individual-hero-illustration.png");
}

#section-1 .content-box {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  align-items: center;
  margin-top: 96px;
  margin-bottom: 100px;
}

#section-1 .heading {
  font-family: "Gilroy-Bold";
  font-size: calc(0.4 * 103px);
  line-height: calc(0.45 * 103px);
  color: #4a4a4a;
  text-align: center;
  max-width: 100%;
}

#section-1 .heading span {
  color: #fe5000;
}

#section-1 .content-text {
  margin-bottom: calc(0.5 * 60px);
  text-align: center;
  max-width: 65%;
}

#section-1 .cta {
  width: 100%;
  text-align: center;
}

.section-1 .cta-button {
  height: calc(0.75 * 60px);
  min-width: max-content;
}

@media only screen and (min-width: 420px) {
  #section-1 .heading {
    max-width: calc(1.6 * 55%);
    font-size: calc(0.5 * 103px);
    line-height: calc(0.55 * 103px);
  }

  #section-1 .content-text {
    max-width: 70%;
  }
}

@media only screen and (min-width: 734px) {
  #section-1 {
    min-height: 85vh;
    background-position: center center;
  }

  #section-1 .content-box {
    padding-left: calc(0.45 * 155px);
    padding-right: calc(0.6 * 155px);
    align-items: flex-start;
  }

  #section-1 .heading {
    max-width: calc(1.45 * 55%);
    font-size: calc(0.6 * 103px);
    line-height: calc(0.7 * 103px);
    text-align: left;
  }

  #section-1 .content-text {
    text-align: left;
    max-width: calc(0.8 * 70%);
  }

  #section-1 .cta {
    text-align: left;
  }

  .section-1 .cta-button {
    height: calc(0.9 * 60px);
  }
}

@media only screen and (min-width: 1069px) {
  #section-1 {
    min-height: 90vh;
  }

  #section-1 .heading {
    max-width: calc(1.1 * 55%);
    font-size: calc(0.7 * 103px);
    line-height: calc(0.75 * 103px);
    text-align: left;
  }

  #section-1 .content-text {
    text-align: left;
    max-width: calc(0.7 * 70%);
    margin-bottom: calc(1 * 60px);
  }

  .section-1 .cta-button {
    height: calc(1 * 60px);
  }
}

/* cta button */
.cta-button {
  background: #fe5000;
  color: #ffff;
  border-radius: 999px;
  border: none;
  padding: 10px 25px;
  font-family: "Gilroy-SemiBold";
  font-size: 14px;
  line-height: 20px;
  min-width: fit-content;
}

.cta-button.loadable {
  background: #ffff;
  color: #FE5000;
  border: 1px solid #FE5000;
}

.cta-button:hover {
  background: #FFFFFF;
  color: #FE5000;
  border: 1px solid #FE5000;
  transition: 250ms;
}

.cta-button:disabled:hover {
  background: #FFFFFF;
  color: #979797;
  border: none;
}

/* BlogCard */
#blog-card {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  margin: auto;
  margin-bottom: 75px;
}

.blog-card-image {
  border-radius: 10px 10px 0 0;
}

.blog-card-image img {
  width: 100%;
}

.blog-card-body {
  padding: 30px 30px 10px 30px;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
}

.blog-card-tag-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.blog-card-tag {
  padding: 1px 8px;
  background-color: #FE5000;
  border-radius: 4px;
  max-width: fit-content;
  font-family: Gilroy-SemiBold;
  color: #FFFFFF;
}

.blog-card-excerpt {
  margin: 10px 0 20px 0;
}

/* blog article */
.blog-article-image {
  border-radius: 10px;
  margin-bottom: 45px;
}

.blog-article-image img {
  width: 100%;
}

@media only screen and (min-width: 420px) {
  .blog-article-body {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 734px) {
  .blog-article-body {
    padding: 0 20%;
  }
}

/* BlogHeader */
#blog-title-section {
  background-color: #E9FEFA;
  height: auto;
  width: 100%;
  padding: 104px 10% 5% 10%;
}

#blog-title-section .heading-2-text {
  margin-top: 2%;
  margin-bottom: 10px;
  text-align: center;
}

#blog-title-section .content-text {
  text-align: center;
}

#blog-title-section .author-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
}

#blog-title-section .author-name-role {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50px;
  align-items: flex-start;
}

#blog-title-section .author-name {
  color: #353535;
}

#blog-title-section .author-role {
  color: #353535;
  opacity: 0.6;
}

/* Email section */
#email-section {
  width: 100%;
  height: auto;
  background-color: #ffeeec;
  padding: 12% 3% 12% 3%;
}

.email-section-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5% 5% 5% 5%;
  text-align: center;
}

#email-section .heading-2-text {
  margin-bottom: 5px;
}

#email-section .content-text {
  max-width: 90%;
}

.email-section-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#email-section .cta-button {
  height: 63px;
  width: calc(100vw - 70px);
  min-width: 200px;
  max-width: 411px;
  margin-top: 9px;
  margin-bottom: 1rem;
  font-size: 16px;
}

@media only screen and (min-width: 768px) {
  #email-section {
    padding: 8% 3% 6% 3%;
  }

  .email-section-text {
    flex: 1 50%;
    text-align: left;
    padding: 3%;
  }

  #email-section .content-text {
    align-self: flex-start;
  }

  .email-section-input {
    flex: 1 50%;
    align-items: flex-start;
    padding: 3%;
  }

  #email-section .cta-button {
    height: 62px;
    width: calc(0.55 * 187px);
    min-width: 170px;
    margin-top: 0.64rem;
    margin-bottom: 0.8rem;
  }
}

@media only screen and (min-width: 1000px) {
  #email-section {
    padding-top: 7%;
  }
}

/* Blog */
#blog-list {
  padding: 5% 10%;
}

#blog-page {
  padding: 5% 15%;
}

/* business-hero */
.Square {
  background: #ffffff;
  box-shadow: 0px 4px 150px 20px rgba(254, 80, 0, 0.05);
  border-radius: 15px;
  margin: 30px;
  height: auto;
  padding: 10px;
}

.Secound {
  background-color: #fff2f2;
}

.battery img {
  height: 68px;
  margin: 20px;
}

.MainImage {
  position: relative;
  width: fit-content;
  margin: auto;
}

.icon img {
  position: absolute;
  right: 3px;
  height: 35px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.icon1 img {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 35px;
}

.BoxHeading {
  font-family: "Gilroy-Bold";
  font-size: 30px;
  line-height: 41px;
  color: #4a4a4a;
}

.BoxSubHeading {
  font-family: "Gilroy-Medium";
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #5e5e5e;
}

@media screen and (max-width: 480px) {
  .icon img {
    position: absolute;
    left: 77%;
    top: 33%;
    height: 30px;
  }

  .icon1 img {
    position: absolute;
    left: 76%;
    height: 30px;
  }
}

.mobile-header-logo img {
  width: 71px;
}

/* Review */

#individual-section {
  margin-left: 100px;
  margin-top: 50px;
  margin-right: 100px;
}

.individual-section__review {
  display: flex;
  color: #222831;
  margin-left: 20px;
}

.individual-section__honour h3 {
  color: #4A4A4A;
  margin-left: 35px;
}

.individual-section__work {
  margin-top: 30px;
  /* margin-left: 25px; */
}

.individual-section__arrow {
  float: right;
  margin-right: 10px;
  margin-top: 90px;
}

.individual-arrow-left {
  color: #9A9A9A;
  width: 50px;
  font-size: 30px;
  cursor: pointer;
}

.individual-arrow-right {
  color: #FE5000;
  font-size: 30px;
  cursor: pointer;
}

.individual-section__row>div {
  height: 350px;
  padding: 20px;
}

.work-content__row {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.work-content__row>div {
  width: 358px;
}

.work-content__row img {
  height: 100px;
  width: 100px;
}

.individual-school {
  background: #FFF1DE;
  border-radius: 10px;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #5E5E5E;
}

.individual-customer {
  background: #FFF1DE;
  border-radius: 10px;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #5E5E5E;
}

.individual-club {
  background: #F2FEEE;
  border-radius: 10px;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #5E5E5E;
}

.individual-image-content {
  padding-top: 120px;
  display: flex;
}

#individual-image {
  margin-top: 45px;
}

#individual-image2 {
  margin-top: auto;
}

#individual-image1 {
  margin-top: 75px;
}

.individual-image-content img {
  height: 50px;
  width: 50px;
}

.individual-content {
  margin-top: 5px;
  margin-left: 15px;
  color: #4A4A4A;
  line-height: 18px;
}

.work-content {
  color: #121E0E;
  margin-top: 35px;
  display: flex;
  font-size: 12px;
}

.work-content img {
  height: 15px;
  width: 15px;
}

.rec.rec-arrow {
  display: none;
}

.rec-pagination button {
  width: 40px;
  height: fit-content;
  border-radius: 0px;
}

.rec-pagination {
  background-color: #DFDFDF;
  width: 160px;
  height: 10px;
}

.rec-pagination .ejXxNI {
  box-shadow: 0 0 1px 3px #DD2C00;
  background-color: #DD2C00;
}

.rec-pagination .jLgnwX {
  box-shadow: none;
}

.rec-pagination .ejXxNI:focus {
  box-shadow: 0 0 1px 3px #DD2C00;
}

.rec-item-wrapper {
  margin-left: 10px;
}

@media all and (max-width: 1200px) and (min-width: 200px) {
  .individual-image-content {
    padding-top: 100px;
    display: flex;
  }

  #individual-image {
    margin-top: auto;
  }

  #individual-image2 {
    margin-top: -30px;
  }

  .rec-pagination button {
    width: fit-content;
    height: fit-content;
    border-radius: 0px;
  }

  .rec-pagination {
    background-color: #DFDFDF;
    width: 90px;
    height: 10px;
  }
}

@media all and (max-width: 768px) {
  #individual-section {
    margin-left: 50px;
    margin-top: 50px;
    margin-right: 50px;
  }

  #individual-image2 {
    margin-top: 18px;
  }

  .individual-section__work #sec {
    margin: auto;
    display: block;
  }

  .individual-section__work .work-content {
    align-items: center;
    justify-content: center;
  }

  .work-content__row {
    flex-direction: column;
    align-items: center;
  }
}


/* Review */

.get-started-success {
  width: 100%;
  background-color: #fe5000;
  color: #ffffff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.get-started-success .heading-text {
  color: #ffffff;
  margin: 0;
  padding: 0 !important;
  text-align: center !important;
}

.get-started-success .content-text {
  color: #ffffff;
}

/* Profile.js */
#dropdown-basic-button {
  background-color: transparent;
  color: #353535;
  border: none;
  /* margin-top: -4px; */
  box-shadow: none;
  font-family: 'Gilroy-Medium';
  font-weight: 900;
  padding-left: 0px;
}

div.dropdown-menu {
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(191, 191, 191, 0.25);
  border: none;
}

div.dropdown-menu.show {
  padding: 6px 0;
  min-width: 145px;
}

a.dropdown-item, button.dropdown-item {
  margin: auto;
  width: 90%;
  height: 30px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  color: #353535;
}

a.dropdown-item:hover, button.dropdown-item:hover {
  background-color: #ffece5;
  border-radius: 5px;
  color: #353535 !important;
}

#profile-user-icon {
  max-width: 1357px;
  height: 107px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 37.6px;
}

#profile-connected-apps {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
  row-gap: 15px;
  margin: 22.4px 0;
}

#profile-connected-apps .item {
  width: 95px;
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(191, 191, 191, 0.25);
  border-radius: 4.8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

#profile-connected-apps .item .vendor-logo {
  width: 70px;
}

.profile-text {
  font-family: 'Gilroy-Medium';
  font-size: 14px;
  margin-left: calc(14px * 0.8);
  line-height: 18px;
  color: #353535;
}

.profile-text-margin {
  margin-left: calc(14px * 0.8);
  margin-right: calc(14px * 0.8);
}

#profile-number{
  margin-left: calc(16px * 0.8);
}

.phorizontal-line {
  border: 0.5px solid #c2c2c2;
  margin-top: 22.4px;
  margin-bottom: 22.4px;
}

hr#under-logo {
  margin-top: 0;
}

#log-out {
  color: #fe5000;
}

/* Blacklist Modal */
.blacklist-modal div {
  border-radius: 47.1px;
  background-color: #FFEEEA;
}

.blacklist-modal-header {
  height: 162px;
  background-color: #FFEEEA;
  padding: 0;
  background-image: url("../images/blacklist-emoji.png");
  background-position: center center;
  background-repeat: no-repeat;
}

.blacklist-modal-body {
  background-color: #fff !important;
}

.blacklist-modal .blacklist-modal-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 47.1px;
  border-bottom-right-radius: 47.1px;
}

#blackList-heading {
  font-family: 'Gilroy-SemiBold';
  font-size: calc(24.4px * 0.8);
  text-align: center;
  color: #000000;
  opacity: 0.8;
  margin-top: calc(26.4px * 0.8);
  margin-bottom: calc(10.5px * 0.8);
}

#blackList-content {
  font-family: 'Gilroy-Light';
  line-height: calc(31px * 0.8);
  font-size: calc(22.1px * 0.8);
  text-align: center;
  color: #7A7A7A;
}

#blacklist-modal-logOut {
  border: none;
  background-color: transparent;
  color: #353535;
  font-family: 'Gilroy-Regular';
  font-size: 11.2px;
  line-height: 16px;
  text-decoration-line: underline;
}

/* dashboard repayment tab */
.refresh-button {
  color: rgb(234, 229, 229);
}

.refresh-button:hover {
  cursor: auto;
}

/* dashboard repayment tab */

/* signwith styles */

#signwithPage {
  background-color: #FFFBF9;
  background-image: url(../images/mobile-background.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
}

#signwith-main-page {
  display: flex;
  flex-direction: column;
  /* width: fit-content; */
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  padding: 50px;
  padding-top: 70px;
  padding-bottom: 70px;
}

#signwith-back-button {
  margin: 36px 0 43px 0;
  width: fit-content;
  display: none;
}

#signwith-back-button img {
  cursor: pointer;
}

#signwith-main-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 60px;
  row-gap: 60px;
}

#signwith-cards-left {
  display: flex;
  flex-direction: column;
  width: fit-content;
  row-gap: 17px;
}

.signwith-container-left {
  padding: 40px 50px;
  display: flex;
  flex-direction: row;
  align-items: center;  
  justify-content: center;
  box-shadow: 0px 2px 10px 0px #BFBFBF40;
  border-radius: 7px;
  background: #ffffff;
}

.signwith-container-left-inner-div {
  min-width: 275px;
}

.signwith-container-left h5 {
  color: #979797;
  font-size: 15px;
}

.signwith-container-left h4 {
  font-family: 'Gilroy-SemiBold';
  color: #353535;
}

.signwith-container-left p {
  font-size: 13px;
  color: #4F4F4F;
}

.signwith-container-left p span {
  color: #00AB84;
  text-decoration: none;
}

.sign-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-link .type {
  font-size: 13px;
  text-decoration: underline;
  text-underline-offset: 1px;
  margin-top: 20px;
}

.sign-link .type:hover {
  cursor: pointer;
  color: #fe5000;
}

.credit-interest a {
  font-size: 13px;
  color: #000000;
  text-decoration: none;
  float: left;
}

.credit-interest a:hover {
  color: #FE5000;
}

.credit-interest .help {
  color: #FE5000;
  float: right;
}

.signwith-container-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 262px;
}

.signwith-container-right h4 {
  font-family: 'Gilroy-SemiBold';
  color: #353535;
  font-size: 24px;
}

.signwith-container-right p {
  font-size: 13px;
}

.signwith-container-right h6 {
  font-weight: bold;
}

/* signwith styles */

/* Switch Portfolio Big */
#multi-select-div {
  display: flex;
  flex-direction: row;
  /* width: 284px; */
  width: fit-content;
  height: auto;
  background: transparent;
  /* border: 1px solid #000000; */
  position: relative;
}

.current-portfolio {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  border-radius: 99px;
  color: #ffffff;
  min-width: 235px;
}

.current-portfolio .dot {
  width: 5px;
  height: 5px;
  background: #24FF00;
  border: none;
  border-radius: 50%;
}

.current-portfolio .portfolio-icon {
  height: 19px;
  width: auto;
  padding: 0 7px;
}

.current-portfolio .name-text {
  font-size: 14px;
}

@keyframes growDown {
  0% {
      transform: scaleY(0)
  }
  80% {
      transform: scaleY(1.1)
  }
  100% {
      transform: scaleY(1)
  }
}

#portfolio-dropdown {
  position: absolute;
  top: 34px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

.dropdown-portfolio-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  border-radius: 99px;
  color: #ffffff;
  width: fit-content;
  min-width: 150px;
}

.dropdown-portfolio-item .dot {
  width: 5px;
  height: 5px;
  background: transparent;
  border: none;
  border-radius: 50%;
}

.dropdown-portfolio-item  .portfolio-icon {
  height: 19px;
  width: auto;
  padding: 0 7px;
}

.dropdown-portfolio-item  .name-text {
  font-size: 14px;
}

.portfolio-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 34px;
  padding: 0 0 0 15px;
}

.portfolio-switch .text {
  font-family: 'Gilroy-Light';
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
}

.portfolio-switch .dropdown-icon {
  width: 15px;
  height: 7.5px;
  margin-left: 5px;  
}

/* Switch Portfolio Big */

/* Switch Portfolio Small */
.current-portfolio-mobile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.current-portfolio-mobile {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  text-align: center;
  margin-right: 8px;
}

.current-portfolio-mobile .portfolio-icon {
  height: 19px;
  width: auto;
  margin: 9px 0 0 0;
}

.current-portfolio-mobile .dropdown-icon {
  width: 15px;
  height: 7.5px;
  margin-left: 5px;  
}

#portfolio-dropdown-mobile {
  position: absolute;
  top: 65px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  z-index: 1000;

}
/* Switch Portfolio Small */


/* ApplicationCard */
#application-card {
  background-color: #ffffff;
  width: 285.5px;
  height: auto;
  /* height: 316px; */
  border-radius: 6px;
  box-shadow: 0px 2px 10px rgba(191, 191, 191, 0.25);
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
}

#application-card .app-banner {
  height: 93px;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}

#application-card .app-banner img {
  width: 140px;
}

#application-card #dashboard-card-upper-balance {
  border-radius: 6px 6px 0 0;
  box-shadow: none;
}

/* ApplicationCard */

/* copy and paste style */

.copyButton {
  width: 100px;
  margin-left: 12px;
  background-color: #FFEAE0;
  color: #FE5000;
  border: none;
  border-radius: 6px;
  height: 39.92px;
  font-family: 'Gilroy-Bold';
  font-weight: 900;
  font-size: 11.65px;
}

/* copy and paste style */

/* Pagination */

.dashboard-pagination-container {
  margin: 10px 0;
  padding: 5px 10px;
  overflow-x: hidden;
  overflow-y: hidden;
  align-self: center;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  padding: 10px 0;
  margin: 0;
  height: auto;
 }

 .pagination li a {
  cursor: default;
  padding: 12px 15px 11px 15px;
  border-radius: 3px;
  border: 0.5px solid #00AB84;
  color: #00AB84 !important;
  font-family: 'Gilroy-Bold';
  font-size: 12px;
  margin-left: 10.5px;
  text-decoration: none;
 }

 .pagination li:not(.disabled) a:hover {
  color: #ffffff !important;
  background-color: #00AB84;
  cursor: pointer;
 }
 
 .pagination li.active a {
  color: #ffffff !important;
  background-color: #00AB84;
 }
 
 .pagination li.disabled a {
  pointer-events: none;
  color: #BCBCBC !important;
  border: 0.5px solid #BCBCBC;
 }
/* Pagination */

/* CurrentBalanceCard */
.icon-img-slash, .icon-img-eye {
  font-size: 18px;
  margin-bottom: 2px;
}
/* CurrentBalanceCard */

.dashboard-refresh-button-small {
  border: 1px solid #000000;
  border-radius: 21px;
  font-size: 12px;
  padding: 2px 9px 1px 9px;
  align-self: end;
  cursor: pointer;
  margin-bottom: 6px;
}

.dashboard-refresh-button-small .icon.zls {
  font-size: 9px;
  margin: 0 4px 3px 0;
  width: 12px;
  height: auto;
}

/* Waitlist */
.waitlist-image {
  min-width: 250px;
  text-align: center;
  margin-top: 10px;
}

.waitlist-image img {
  width: 250px;
}

.waitlist-text-heading {
  font-family: 'Gilroy-Bold';
  font-size: 18.72px;
  line-height: 20.78px;
  text-align: center;
  margin-top: 30px;
}

.waitlist-text-body {
  font-family: 'Gilroy-Regular';
  font-size: 14.56px;
  line-height: 21.84px;
  color: #7A7A7A;
  text-align: center;
  margin-top: 10px;
}

.waitlist-button {
  margin-top: 50px;
}
/* Waitlist */

/* partner Auth */
.partnerAuth-logo .carrot {
  width: 50px;
  margin-right: 7px;
}

.partnerAuth-logo .with {
  width: 30px;
  margin-right: 7px;
}

.partnerAuth-logo .partner-logo {
  margin-left: -2px;
  margin-bottom: 10px;
}

.heading.partnerAuth {
  margin: 10px 0 0 0;
}

.subheading.partnerAuth {
  margin: 11px 0 30px 0;
}

.partnerAuth-input.email {
  margin: 0 0 32px 0;
}

.partnerAuth-otp-resend {
  width: 100%;
  font-family: 'Gilroy-Light';
  font-size: 12px;
  line-height: 18px;
  margin: 4.8px 0 34px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.partnerAuth-otp-resend p {
  color: #00ab84;
}

.partnerAuth-button {
  margin: 20px 0 0 0;
}

.partnerAuth-footer {
  font-size: 12px;
  line-height: 18px;
  color: #7a7a7a;
  text-align: center;
  margin-top: 52px;
  cursor: pointer;
}
/* partner Auth */

.password-evaluation {
  margin: 10px 0 10px 0;
}

.password-evaluation .item img {
  width: 13px;
}

.password-evaluation .item span {
  font-family: 'Gilroy-Regular';
  font-size: 12.8px;
  line-height: 23.2px;
  margin: 0 0 0 6px;
}

.page-logo-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0 0;
}

.waitlist-multiselect-web {
  margin: 20px 0 0 0;
}

.modal-container {
  animation: growDown 300ms ease-in-out forwards;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  flex-direction: column;
  max-height: 600px;
  overflow-y: auto;
  padding: 15px 25px;
  position: absolute;
  row-gap: 4px;
  top: 94px;
  transform-origin: top center;
  width: 450px;
  z-index: 1000;
  border-radius: 15px;
}

.notification-center {
  font-size: 16px;
}

.notificationList {
  list-style-type: none;
  padding: 0;
}

.notificationList li {
  align-items: flex-start;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  padding: 15px 0;
}

.notificationList-img {
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 15px;
  min-width: 40px;
}

.notificationList-img img {
  width: 40px;
  height: 40px; 
  border-radius: 50%
}

.notificationContent {
  width: 100%;
}

.notificationContentHeader {
  display: flex;
  justify-content: space-between;
}

.notificationContentSubject {
  margin-bottom: '3px';
  color: #353535;
  font-family: "Gilroy-Semibold";
  font-size: 14px;
  line-height: 14px;
}

.bold {
  font-weight: 700;
}

.notificationContentBody {
  color: #353535; 
  font-family: "Gilroy-Regular";
  font-size: 14px;
  line-height: 21px;
  opacity: 0.6;
}

.notificationContentDate {
  color: #555; 
  font-size: 10px; 
  margin-top: '5px';
  font-style: italic;
}

.notificationDot {
  background: #FE5000;
  border-radius: 50%;
  height: 10px;
  position: absolute;
  right: -2px;
  top: 33px;
  width: 10px
}

.notificationDotMobile {
  background: #FE5000;
  border-radius: 50%;
  height: 10px;
  position: absolute;
  right: 6px;
  top: 33px;
  width: 10px
}

.notificationFooter {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.notificationClear {
  color: #FE5000;
  font-size: 14px;
  text-decoration: underline;
}

.notificationList-pagination-box {
  display: flex;
  justify-content: flex-end;
  padding: 14px 25px 0px 0px;
}

.notificationList-empty {
  padding: 25px 0px 10px 0px;
}

#notification-center-mobile-view {
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 750px;
  margin: auto;
}

.notification-center {
  font-family: "Gilroy-Semibold";
  font-size: 18px;
}

#notification-center-mobile-view-header {
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.notificationList-pagination-box {
  width: 100%;
  padding: 10px 20px 0px 0px;
}

.notificationLoader{
  display: flex;
  gap: 20px;
  padding: 10px 0px;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.notificationLoaderCircle{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #D3D3D3;
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.notificationLoaderRight{
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
.notificationLoaderTop{
  display: flex;
  justify-content: space-between;
}

.notificationLoaderTopLeft{
  height: 15px;
  width: 150px;
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background-color: #D3D3D3;
}

.notificationLoaderTopRight{
  height: 7px;
  width: 100px;
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background-color: #D3D3D3;
}

.notificationLoaderMiddle{
  height: 10px;
  width: 100px;
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background-color: #D3D3D3;
}

.notificationLoaderBottom{
  height: 50px;
  width: 100%;
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background-color: #D3D3D3;
}

.no-saved-accounts {
  display: flex;
  flex-direction: row;
  row-gap: 5px;
  align-items: center;
}

.no-saved-accounts p {
  font-family: 'Gilroy-Regular';
  font-size: 0.85rem;
  color: #353535;
  line-height: 18px;
  margin-top: 3px;
  margin-bottom: 0;
}

.no-saved-accounts img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}