.question-container-open {
  max-height: 500px !important;
  transition: max-height 1.4s ease-in;
  /* overflow-y: scroll !important; */
}

.question-container-close {
  max-height: 0 !important;
  transition: max-height 0.4s ease-out;
}

@media screen and (max-width: 768px) {
  .question-container-open {
    overflow-y: scroll !important;
  }
}